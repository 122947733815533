.Vue-Toastification__container {
  z-index: 9999;
  position: fixed;
  width: 400px;
  box-sizing: border-box;
  display: flex;
  min-height: 100%;
  color: #fff;
  flex-direction: column;
  pointer-events: none;
}[dir] .Vue-Toastification__container {
  padding: 4px;
}
@media only screen and (min-width : 600px) {
  .Vue-Toastification__container.top-left, .Vue-Toastification__container.top-right, .Vue-Toastification__container.top-center {
    top: 1em;
  }
  .Vue-Toastification__container.bottom-left, .Vue-Toastification__container.bottom-right, .Vue-Toastification__container.bottom-center {
    bottom: 1em;
    flex-direction: column-reverse;
  }
  [dir=ltr] .Vue-Toastification__container.top-left, [dir=ltr] .Vue-Toastification__container.bottom-left {
    left: 1em;
  }
  [dir=rtl] .Vue-Toastification__container.top-left, [dir=rtl] .Vue-Toastification__container.bottom-left {
    right: 1em;
  }
  [dir=ltr] .Vue-Toastification__container.top-left .Vue-Toastification__toast, [dir=ltr] .Vue-Toastification__container.bottom-left .Vue-Toastification__toast {
    margin-right: auto;
  }
  [dir=rtl] .Vue-Toastification__container.top-left .Vue-Toastification__toast, [dir=rtl] .Vue-Toastification__container.bottom-left .Vue-Toastification__toast {
    margin-left: auto;
  }
  @supports not (-moz-appearance: none) {
    [dir=ltr] .Vue-Toastification__container.top-left .Vue-Toastification__toast--rtl, [dir=ltr] .Vue-Toastification__container.bottom-left .Vue-Toastification__toast--rtl {
      margin-right: unset;
      margin-left: auto;
    }
    [dir=rtl] .Vue-Toastification__container.top-left .Vue-Toastification__toast--rtl, [dir=rtl] .Vue-Toastification__container.bottom-left .Vue-Toastification__toast--rtl {
      margin-left: unset;
      margin-right: auto;
    }
  }
  [dir=ltr] .Vue-Toastification__container.top-right, [dir=ltr] .Vue-Toastification__container.bottom-right {
    right: 1em;
  }
  [dir=rtl] .Vue-Toastification__container.top-right, [dir=rtl] .Vue-Toastification__container.bottom-right {
    left: 1em;
  }
  [dir=ltr] .Vue-Toastification__container.top-right .Vue-Toastification__toast, [dir=ltr] .Vue-Toastification__container.bottom-right .Vue-Toastification__toast {
    margin-left: auto;
  }
  [dir=rtl] .Vue-Toastification__container.top-right .Vue-Toastification__toast, [dir=rtl] .Vue-Toastification__container.bottom-right .Vue-Toastification__toast {
    margin-right: auto;
  }
  @supports not (-moz-appearance: none) {
    [dir=ltr] .Vue-Toastification__container.top-right .Vue-Toastification__toast--rtl, [dir=ltr] .Vue-Toastification__container.bottom-right .Vue-Toastification__toast--rtl {
      margin-left: unset;
      margin-right: auto;
    }
    [dir=rtl] .Vue-Toastification__container.top-right .Vue-Toastification__toast--rtl, [dir=rtl] .Vue-Toastification__container.bottom-right .Vue-Toastification__toast--rtl {
      margin-right: unset;
      margin-left: auto;
    }
  }
  [dir=ltr] .Vue-Toastification__container.top-center, [dir=ltr] .Vue-Toastification__container.bottom-center {
    left: 50%;
    margin-left: -200px;
  }
  [dir=rtl] .Vue-Toastification__container.top-center, [dir=rtl] .Vue-Toastification__container.bottom-center {
    right: 50%;
    margin-right: -200px;
  }
  [dir=ltr] .Vue-Toastification__container.top-center .Vue-Toastification__toast, [dir=ltr] .Vue-Toastification__container.bottom-center .Vue-Toastification__toast {
    margin-left: auto;
    margin-right: auto;
  }
  [dir=rtl] .Vue-Toastification__container.top-center .Vue-Toastification__toast, [dir=rtl] .Vue-Toastification__container.bottom-center .Vue-Toastification__toast {
    margin-right: auto;
    margin-left: auto;
  }
}
@media only screen and (max-width : 600px) {
  .Vue-Toastification__container {
    width: 100vw;
  }
  [dir] .Vue-Toastification__container {
    padding: 0;
    margin: 0;
  }
  [dir=ltr] .Vue-Toastification__container {
    left: 0;
  }
  [dir=rtl] .Vue-Toastification__container {
    right: 0;
  }
  .Vue-Toastification__container .Vue-Toastification__toast {
    width: 100%;
  }
  .Vue-Toastification__container.top-left, .Vue-Toastification__container.top-right, .Vue-Toastification__container.top-center {
    top: 0;
  }
  .Vue-Toastification__container.bottom-left, .Vue-Toastification__container.bottom-right, .Vue-Toastification__container.bottom-center {
    bottom: 0;
    flex-direction: column-reverse;
  }
}

.Vue-Toastification__toast {
  display: inline-flex;
  position: relative;
  max-height: unset;
  min-height: unset;
  box-sizing: border-box;
  justify-content: space-between;
  font-family: inherit;
  max-width: 400px;
  min-width: 326px;
  pointer-events: auto;
  overflow: hidden;
}

[dir] .Vue-Toastification__toast {
  margin-bottom: 1rem;
  padding: 22px 24px;
  border-radius: 8px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  transform: translateZ(0);
}

[dir=ltr] .Vue-Toastification__toast {
  direction: ltr;
}

[dir=rtl] .Vue-Toastification__toast {
  direction: rtl;
}
[dir=ltr] .Vue-Toastification__toast--rtl {
  direction: rtl;
}
[dir=rtl] .Vue-Toastification__toast--rtl {
  direction: ltr;
}
.Vue-Toastification__toast--default {
  color: #0875d6;
}
[dir] .Vue-Toastification__toast--default {
  background-color: #fff;
}
.Vue-Toastification__toast--info {
  color: #00cfe8;
}
[dir] .Vue-Toastification__toast--info {
  background-color: #fff;
}
.Vue-Toastification__toast--success {
  color: #28c76f;
}
[dir] .Vue-Toastification__toast--success {
  background-color: #fff;
}
.Vue-Toastification__toast--error {
  color: #ea5455;
}
[dir] .Vue-Toastification__toast--error {
  background-color: #fff;
}
.Vue-Toastification__toast--warning {
  color: #ff9f43;
}
[dir] .Vue-Toastification__toast--warning {
  background-color: #fff;
}
@media only screen and (max-width : 600px) {
  [dir] .Vue-Toastification__toast {
    border-radius: 0px;
    margin-bottom: 0.5rem;
  }
}
.Vue-Toastification__toast-body {
  flex: 1;
  line-height: 24px;
  font-size: 16px;
  word-break: break-word;
  white-space: pre-wrap;
}
.Vue-Toastification__toast-component-body {
  flex: 1;
}
.Vue-Toastification__toast.disable-transition {
  transition: none !important;
}
[dir=ltr] .Vue-Toastification__toast.disable-transition {
  animation: none !important;
}
[dir=rtl] .Vue-Toastification__toast.disable-transition {
  animation: none !important;
}

.Vue-Toastification__close-button {
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  outline: none;
  transition: 0.3s ease;
  align-items: center;
  color: #fff;
  opacity: 0.3;
  transition: visibility 0s, opacity 0.2s linear;
}

[dir] .Vue-Toastification__close-button {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

[dir=ltr] .Vue-Toastification__close-button {
  padding-left: 10px;
}

[dir=rtl] .Vue-Toastification__close-button {
  padding-right: 10px;
}
.Vue-Toastification__close-button:hover, .Vue-Toastification__close-button:focus {
  opacity: 1;
}
.Vue-Toastification__toast:not(:hover) .Vue-Toastification__close-button.show-on-hover {
  opacity: 0;
}
[dir=ltr] .Vue-Toastification__toast--rtl .Vue-Toastification__close-button {
  padding-left: unset;
  padding-right: 10px;
}
[dir=rtl] .Vue-Toastification__toast--rtl .Vue-Toastification__close-button {
  padding-right: unset;
  padding-left: 10px;
}

@keyframes scale-x-frames {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Vue-Toastification__progress-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5px;
  z-index: 10000;
}
[dir] .Vue-Toastification__progress-bar {
  background-color: rgba(255, 255, 255, 0.7);
}
[dir=ltr] .Vue-Toastification__progress-bar {
  left: 0;
  transform-origin: left;
  animation: scale-x-frames linear 1 forwards;
}
[dir=rtl] .Vue-Toastification__progress-bar {
  right: 0;
  transform-origin: right;
  animation: scale-x-frames linear 1 forwards;
}
[dir=ltr] .Vue-Toastification__toast--rtl .Vue-Toastification__progress-bar {
  right: 0;
  left: unset;
  transform-origin: right;
}
[dir=rtl] .Vue-Toastification__toast--rtl .Vue-Toastification__progress-bar {
  left: 0;
  right: unset;
  transform-origin: left;
}

.Vue-Toastification__icon {
  outline: none;
  transition: 0.3s ease;
  align-items: center;
  width: 20px;
  height: 100%;
}

[dir] .Vue-Toastification__icon {
  background: transparent;
  border: none;
  padding: 0;
}

[dir=ltr] .Vue-Toastification__icon {
  margin: auto 18px auto 0px;
}

[dir=rtl] .Vue-Toastification__icon {
  margin: auto 0px auto 18px;
}
[dir=ltr] .Vue-Toastification__toast--rtl .Vue-Toastification__icon {
  margin: auto 0px auto 18px;
}
[dir=rtl] .Vue-Toastification__toast--rtl .Vue-Toastification__icon {
  margin: auto 18px auto 0px;
}

/* ----------------------------------------------
 * Modified version from Animista
 * Animista is Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
@keyframes fadeOutTop {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50px);
    opacity: 0;
  }
}
@keyframes fadeOutLeft-ltr {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-50px);
    opacity: 0;
  }
}
@keyframes fadeOutLeft-rtl {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(50px);
    opacity: 0;
  }
}
@keyframes fadeOutBottom {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(50px);
    opacity: 0;
  }
}
@keyframes fadeOutRight-ltr {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(50px);
    opacity: 0;
  }
}
@keyframes fadeOutRight-rtl {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-50px);
    opacity: 0;
  }
}
@keyframes fadeInLeft-ltr {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fadeInLeft-rtl {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fadeInRight-ltr {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fadeInRight-rtl {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fadeInTop {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fadeInBottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
[dir=ltr] .Vue-Toastification__fade-enter-active.top-left, [dir=ltr] .Vue-Toastification__fade-enter-active.bottom-left {
  animation-name:  fadeInLeft-ltr ;
}
[dir=rtl] .Vue-Toastification__fade-enter-active.top-left, [dir=rtl] .Vue-Toastification__fade-enter-active.bottom-left {
  animation-name:  fadeInLeft-rtl ;
}
[dir=ltr] .Vue-Toastification__fade-enter-active.top-right, [dir=ltr] .Vue-Toastification__fade-enter-active.bottom-right {
  animation-name:  fadeInRight-ltr ;
}
[dir=rtl] .Vue-Toastification__fade-enter-active.top-right, [dir=rtl] .Vue-Toastification__fade-enter-active.bottom-right {
  animation-name:  fadeInRight-rtl ;
}
[dir=ltr] .Vue-Toastification__fade-enter-active.top-center {
  animation-name: fadeInTop;
}
[dir=rtl] .Vue-Toastification__fade-enter-active.top-center {
  animation-name: fadeInTop;
}
[dir=ltr] .Vue-Toastification__fade-enter-active.bottom-center {
  animation-name: fadeInBottom;
}
[dir=rtl] .Vue-Toastification__fade-enter-active.bottom-center {
  animation-name: fadeInBottom;
}

[dir=ltr] .Vue-Toastification__fade-leave-active.top-left, [dir=ltr] .Vue-Toastification__fade-leave-active.bottom-left {
  animation-name:  fadeOutLeft-ltr ;
}

[dir=rtl] .Vue-Toastification__fade-leave-active.top-left, [dir=rtl] .Vue-Toastification__fade-leave-active.bottom-left {
  animation-name:  fadeOutLeft-rtl ;
}
[dir=ltr] .Vue-Toastification__fade-leave-active.top-right, [dir=ltr] .Vue-Toastification__fade-leave-active.bottom-right {
  animation-name:  fadeOutRight-ltr ;
}
[dir=rtl] .Vue-Toastification__fade-leave-active.top-right, [dir=rtl] .Vue-Toastification__fade-leave-active.bottom-right {
  animation-name:  fadeOutRight-rtl ;
}
[dir=ltr] .Vue-Toastification__fade-leave-active.top-center {
  animation-name: fadeOutTop;
}
[dir=rtl] .Vue-Toastification__fade-leave-active.top-center {
  animation-name: fadeOutTop;
}
[dir=ltr] .Vue-Toastification__fade-leave-active.bottom-center {
  animation-name: fadeOutBottom;
}
[dir=rtl] .Vue-Toastification__fade-leave-active.bottom-center {
  animation-name: fadeOutBottom;
}

[dir] .Vue-Toastification__fade-move {
  transition-timing-function: ease-in-out;
  transition-property: all;
  transition-duration: 400ms;
}

[dir] .Vue-Toastification__toast {
  padding: 1rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 0.428rem;
}

@media only screen and (max-width: 600px) {
  .Vue-Toastification__container .Vue-Toastification__toast {
    width: 90%;
  }
}
[dir] .dark-layout .Vue-Toastification__toast {
  background-color: #283046;
}

.Vue-Toastification__container {
  width: auto !important;
}