.btn-scroll-to-top[data-v-7f94a6f1] {
  position: fixed;
  bottom: 5%;
  z-index: 99;
  opacity: 0;
  transition: all 0.5s ease;
}[dir=ltr] .btn-scroll-to-top[data-v-7f94a6f1] {
  right: 30px;
}[dir=rtl] .btn-scroll-to-top[data-v-7f94a6f1] {
  left: 30px;
}
.btn-scroll-to-top.show[data-v-7f94a6f1] {
  opacity: 1;
}